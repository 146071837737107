var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "境内项目人员信息审核",
            },
            on: {
              "head-cancel": _vm.headCancel,
              "head-save": _vm.headSave,
              "head-saveBack": function ($event) {
                return _vm.headSave(true)
              },
              "head-submit": _vm.headSubmit,
            },
          }),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "margin-top": "20px" },
              attrs: { model: _vm.dataForm, "label-width": "140px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "统计日期", prop: "statDate" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", [_vm._v("统计日期:")]),
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              disabled:
                                _vm.type == "view" || _vm.type == "workflow",
                              placeholder: "请选择统计日期",
                            },
                            on: { change: _vm.chooseDate },
                            model: {
                              value: _vm.dataForm.statDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "statDate", $$v)
                              },
                              expression: "dataForm.statDate",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "填报日期", prop: "updateTime" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("填报日期:")]),
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              disabled: "true",
                              placeholder: "保存后自动生成",
                            },
                            model: {
                              value: _vm.dataForm.updateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "updateTime", $$v)
                              },
                              expression: "dataForm.updateTime",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "form-divider" }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.dataForm, "label-width": "140px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "projectName" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("项目名称:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "projectName", $$v)
                              },
                              expression: "dataForm.projectName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务类型",
                            prop: "businessTypeName",
                          },
                        },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("业务类型:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.businessTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "businessTypeName", $$v)
                              },
                              expression: "dataForm.businessTypeName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目状态",
                            prop: "projectStatusName",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "由项目数据带出",
                                "popper-append-to-body": false,
                                disabled: true,
                              },
                              model: {
                                value: _vm.dataForm.projectStatusName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "projectStatusName",
                                    $$v
                                  )
                                },
                                expression: "dataForm.projectStatusName",
                              },
                            },
                            _vm._l(_vm.projectStatusList, function (item) {
                              return _c("el-option", {
                                key: item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "州/省", prop: "provinceName" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("州/省:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.provinceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "provinceName", $$v)
                              },
                              expression: "dataForm.provinceName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目经理", prop: "pmCame" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("项目经理:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.pmCame,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "pmCame", $$v)
                              },
                              expression: "dataForm.pmCame",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "phoneNumber" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("联系电话:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "phoneNumber", $$v)
                              },
                              expression: "dataForm.phoneNumber",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "总人数", prop: "totalCount" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("总人数:")]),
                          ]),
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 30px)" },
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "由项目人员信息计算得出",
                            },
                            model: {
                              value: _vm.totalCount,
                              callback: function ($$v) {
                                _vm.totalCount = $$v
                              },
                              expression: "totalCount",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.totalCountS,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline iconLine",
                              }),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("head-layout", { attrs: { "head-title": "项目人员信息" } }),
          _c(
            "el-form",
            {
              ref: "formLayout1",
              staticClass: "demo-ruleForm",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.dataForm,
                rules: _vm.rules,
                "label-width": "160px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司在册职工:",
                            prop: "regeditedChineseCnt",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 30px)" },
                            attrs: {
                              readonly: _vm.dataForm.dataState != "3",
                              "controls-position": "right",
                              placeholder: "请输入公司在册职工",
                              maxlength: "8",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dataForm.regeditedChineseCnt,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm,
                                  "regeditedChineseCnt",
                                  $$v
                                )
                              },
                              expression: "dataForm.regeditedChineseCnt",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.regeditedChineseCntS,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline iconLine",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分包商(含劳务)人数:",
                            prop: "subcontractorChineseCnt",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 30px)" },
                            attrs: {
                              readonly: _vm.dataForm.dataState != "3",
                              "controls-position": "right",
                              placeholder: "请输入分包商（含劳务）人数",
                              maxlength: "8",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dataForm.subcontractorChineseCnt,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm,
                                  "subcontractorChineseCnt",
                                  $$v
                                )
                              },
                              expression: "dataForm.subcontractorChineseCnt",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.subcontractorChineseCntS,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline iconLine",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "人员增减说明:", prop: "remark1" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 30px)" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 4 },
                              maxlength: "200",
                              "show-word-limit": true,
                              readonly: _vm.dataForm.dataState != "3",
                            },
                            model: {
                              value: _vm.dataForm.remark1,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "remark1", $$v)
                              },
                              expression: "dataForm.remark1",
                            },
                          }),
                          _c("i", {
                            staticClass: "el-icon-refresh iconLine",
                            on: { click: _vm.remark1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("head-layout", { attrs: { "head-title": "项目现场信息" } }),
          _c("form-layout", {
            ref: "formLayout2",
            attrs: { column: _vm.form2Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }