<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        head-title="境内项目人员信息审核"
        @head-cancel="headCancel"
        @head-save="headSave"
        @head-saveBack="headSave(true)"
        @head-submit="headSubmit"
      ></head-layout>
      <el-form :model="dataForm" ref="ruleForm" label-width="140px" class="demo-ruleForm"
               style="margin-top: 20px;">
        <el-row :gutter="24">
<!--          <el-col :span="8">-->
<!--            <el-form-item label="项目编码" prop="projectCode">-->
<!--              <template slot="label">-->
<!--                <span>项目编码:</span>-->
<!--              </template>-->
<!--              <el-input-->
<!--                readonly="true"-->
<!--                v-model="dataForm.projectCode"-->
<!--                controls-position="right"-->
<!--                placeholder="由项目数据带出"-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="8">
            <el-form-item label="统计日期" prop="statDate">
              <template slot="label">
                <span style="color:red">* </span>
                <span>统计日期:</span>
              </template>
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="dataForm.statDate"
                type="date"
                @change="chooseDate"
                :disabled="type == 'view' || type == 'workflow'"
                placeholder="请选择统计日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="填报日期" prop="updateTime">
              <template slot="label">
                <span>填报日期:</span>
              </template>
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="dataForm.updateTime"
                type="date"
                disabled="true"
                placeholder="保存后自动生成"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-divider"></div>
      </el-form>
      <el-form :model="dataForm" ref="ruleForm" label-width="140px" class="demo-ruleForm">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="项目名称" prop="projectName">
              <template slot="label">
                <span>项目名称:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.projectName"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务类型" prop="businessTypeName">
              <template slot="label">
                <span>业务类型:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.businessTypeName"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目状态" prop="projectStatusName">
              <el-select
                v-model="dataForm.projectStatusName"
                placeholder="由项目数据带出"
                :popper-append-to-body="false"
                :disabled="true"
              >
                <el-option
                  v-for="item in projectStatusList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="州/省" prop="provinceName">
              <template slot="label">
                <span>州/省:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.provinceName"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目经理" prop="pmCame">
              <template slot="label">
                <span>项目经理:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.pmCame"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="phoneNumber">
              <template slot="label">
                <span>联系电话:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.phoneNumber"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总人数" prop="totalCount">
              <template slot="label">
                <span>总人数:</span>
              </template>
              <el-input
                readonly="true"
                v-model="totalCount"
                controls-position="right"
                style="width: calc(100% - 30px)"
                placeholder="由项目人员信息计算得出"
              ></el-input>
              <el-tooltip class="item" effect="dark" :content="totalCountS" placement="bottom">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <head-layout
        head-title="项目人员信息"
      ></head-layout>
      <el-form :model="dataForm" :rules="rules" ref="formLayout1" label-width="160px" class="demo-ruleForm"
               style="margin-top: 20px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司在册职工:" prop="regeditedChineseCnt">
              <el-input
                :readonly="dataForm.dataState != '3'"
                v-model="dataForm.regeditedChineseCnt"
                controls-position="right"
                placeholder="请输入公司在册职工"
                style="width: calc(100% - 30px)"
                maxlength="8"
                show-word-limit
              ></el-input>
              <el-tooltip class="item" effect="dark" :content="regeditedChineseCntS" placement="bottom">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分包商(含劳务)人数:" prop="subcontractorChineseCnt">
              <el-input
                :readonly="dataForm.dataState != '3'"
                v-model="dataForm.subcontractorChineseCnt"
                controls-position="right"
                placeholder="请输入分包商（含劳务）人数"
                style="width: calc(100% - 30px)"
                maxlength="8"
                show-word-limit
              ></el-input>
              <el-tooltip class="item" effect="dark" :content="subcontractorChineseCntS" placement="bottom">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="人员增减说明:" prop="remark1">
              <el-input v-model="dataForm.remark1" type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                         maxlength="200" :show-word-limit="true"
                        :readonly="dataForm.dataState != '3'" style="width: calc(100% - 30px)"></el-input>
              <i class="el-icon-refresh iconLine" @click="remark1"></i>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--      <form-layout-->
      <!--        ref="formLayout1"-->
      <!--        :column="form1Column"-->
      <!--        :dataForm.sync="dataForm"-->
      <!--      ></form-layout>-->
      <head-layout
        head-title="项目现场信息"
      ></head-layout>
      <form-layout
        ref="formLayout2"
        :column="form2Column"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-main>
  </div>
</template>
<script>

import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {saveDomestic, getDomesticDetail} from "@/api/foreignReport/domesticReport";
import {mapGetters} from "vuex";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {dictionaryBiz} from "@/api/reportTasks";
import {readonly} from "vue";


export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  mixins: [exForm, draft],
  data() {
    return {
      endData: "", //上次时间
      startData: "", // 这次时间
      personnel: "", // 这次时间
      totalCountS: '',
      regeditedChineseCntS: '',
      subcontractorChineseCntS: '',
      projectStatusList: [],
      processTemplateKey: "report_domestic",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      type: '',
      ruleForm: {},
      rules: {
        regeditedChineseCnt: [
          {required: true, message: '请输入公司在册职工', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        subcontractorChineseCnt: [
          {required: true, message: '请输入分包商（含劳务）人数数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
      dataForm: {},
      orgCode: "",
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      if (this.type == 'workflow' && this.dataForm.dataState == 3) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
          });
      }
      if (this.type != 'workflow') {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
          });
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-saveBack",
            type: "button",
            icon: "",
          });
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.submitBtn'),
            emit: "head-submit",
            type: "button",
            icon: "",
          });
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.celBtn'),
            emit: "head-cancel",
            type: "button",
            icon: "",
          });
      }
      return buttonBtn;
    },
    form1Column() {
      return [
        {
          label: "公司在册职工",
          prop: "regeditedChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          readonly: this.dataForm.dataState != "3",
          rules: [{
            required: true,
            message: "请输入公司在册职工数",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
        {
          label: "分包商（含劳务）人数",
          prop: "subcontractorChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          readonly: this.dataForm.dataState != "3",
          rules: [{
            required: true,
            message: "",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
      ]
    },
    form2Column() {
      return [
        {
          label: "项目进展情况",
          prop: "projectProgress",
          labelWidth: 120,
          type: 'textarea',
          maxlength: 500,
          readonly: this.dataForm.dataState != "3",
          span: 24,
          showWordLimit: true
        },
      ]
    },
    totalCount() {
      let number = 0;
      const regeditedChineseCnt = this.dataForm.regeditedChineseCnt && this.dataForm.regeditedChineseCnt.toString().replace(/\D/g, '');
      const subcontractorChineseCnt = this.dataForm.subcontractorChineseCnt && this.dataForm.subcontractorChineseCnt.toString().replace(/\D/g, '');
      if (regeditedChineseCnt || subcontractorChineseCnt) {
        number = (regeditedChineseCnt ? Number(regeditedChineseCnt) : 0)
          + (subcontractorChineseCnt ? Number(subcontractorChineseCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.totalCount = number;
      return number;
    },
  },
  methods: {
    remark1() {
      if (this.dataForm.referenceStatistical) {
        if (this.startData == this.endData) {
          this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          let regedited = this.dataForm.regeditedChineseCnt - this.dataForm.regeditedChineseCntS
          let subcontractor = this.dataForm.subcontractorChineseCnt - this.dataForm.subcontractorChineseCntS
          this.dataForm.remark1 = this.personnel + (regedited == 0 ? "" : (regedited > 0 ? "增加" : "减少") + Math.abs(regedited) + "公司在册职工.") + (subcontractor == 0 ? "" : (subcontractor > 0 ? "增加" : "减少") + Math.abs(subcontractor) + "名分包商（含劳务）人数.")
        } else {
          let regedited = this.dataForm.regeditedChineseCnt - this.dataForm.regeditedChineseCntS
          let subcontractor = this.dataForm.subcontractorChineseCnt - this.dataForm.subcontractorChineseCntS
          this.dataForm.remark1 = this.personnel + (regedited == 0 ? "" : (regedited > 0 ? "增加" : "减少") + Math.abs(regedited) + "公司在册职工.") + (subcontractor == 0 ? "" : (subcontractor > 0 ? "增加" : "减少") + Math.abs(subcontractor) + "名分包商（含劳务）人数.")
        }
      } else {
        this.$message.warning("参考数据不是已审核通过数据,需手动填写!")
      }
    },
    //提交
    headSubmit() {
      if (this.dataForm.id) {
        if (this.dataForm.statDate) {
          this.$refs.formLayout.$refs.form.validate(valid => {
            if (valid) {
              this.$confirm("确定将提交审核?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              })
                .then(() => {
                  this.$loading();
                  let dataForm = this.$refs.formLayout.dataForm
                  this.rpTaskAttributeSubmit(dataForm);
                })
                .then(() => {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                  this.$loading().close();
                });
              this.$refs.formLayout.$refs.form.allDisabled = false
            }
          })
        } else {
          this.$message.warning("统计日期为空");
        }
      } else {
        this.$message.warning("请先保存数据");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 1
      this.dataForm.orgCode = this.orgCode
      this.formProcess.id = data.id;
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true,true).then((res) => {
        data.processInsId = res.data.data;
        data.dataState = 2; // 设置状态为新增生成 审核中
        saveDomestic(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    headSave(clo = false) {
      this.$refs.formLayout1.validate((valid1) => {
        if (valid1) {
          if (this.dataForm.statDate) {
            if (!this.dataForm.id) {
              this.dataForm.createUserName = this.userInfo.real_name
            }
            this.dataForm.updateUserName = this.userInfo.real_name
            this.dataForm.projectArea = 1
            this.dataForm.dataStateType = this.type
            saveDomestic(this.dataForm).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
                this.$message.success("保存成功");
              }
            }).then(() => {
              if (clo) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
            })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    chooseDate(e) {
      if (this.dataForm.referenceStatistical) {
        if (this.startData) {
          this.endData = e;
          if (this.startData == this.endData) {
            this.selectList();
          } else {
            this.dataForm.remark1 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.dataForm.remark2 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          }
        }
      }
    },
  },
  mounted() {
    dictionaryBiz("prj_state").then((res) => {
      this.projectStatusList = res.data.data;
    });
    let type = this.$route.query.type;
    this.type = type
    if (type === 'workflow') {
      let id = this.$route.query.id
      getDomesticDetail(id).then((res) => {
        if (res.data.code === 200) {
          this.startData = res.data.data.statDate
          this.endData = res.data.data.statDate
          this.dataForm = res.data.data
          this.totalCountS = this.dataForm.totalCountS
          this.regeditedChineseCntS = this.dataForm.regeditedChineseCntS
          this.subcontractorChineseCntS = this.dataForm.subcontractorChineseCntS
          if (this.dataForm.dataState !== '3') {
            this.$refs.formLayout2.$refs.form.alldisabled = true;
          }
        }
      })

    }
  },
}
</script>

<style>
.form-divider {
  display: block;
  height: 1px;
  margin: 16px 0;
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  overflow: hidden;
}

.form-divider::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.iconLine {
  margin-left: 6px;
  font-size: 24px;
}

</style>
